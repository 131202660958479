import React from "react";
import "twin.macro";
import { SEO } from "../components";
import { BlankPageNoImage, LocationBlock } from "../components/common";

const intro = `
We offer custom made ear moulds for noise reduction against things such as snoring, traffic noise and air conditioning units - these plugs are specially designed to ensure they do not hinder your comfort when sleeping, they are small, soft and made to the shape of your ears for comfort whilst blocking out unwanted noise whilst sleeping and resting.
<br /> <br />
Custom made ear moulds are also available for individuals who grind their teeth at night which can lead to teeth issues, nearby joints problems as well as causing general discomfort in the mouth and head.
`;

const SoundSleep = () => {
  return (
    <>
      <SEO title="Sound Sleep Solutions" />
      <BlankPageNoImage
        header="Sound Sleep Solutions"
        intro={intro}
        ariaLabel="Sound Sleep Solutions"
      >
        <LocationBlock />
      </BlankPageNoImage>
    </>
  );
};

export default SoundSleep;
